@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i|Raleway:300,300i,400,400i,500,500i,600,600i,700,700i|Poppins:300,300i,400,400i,500,500i,600,600i,700,700i');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,300;0,400;0,500;0,600;0,700;0,800;1,500&display=swap');
@import url('https://fonts.googleapis.com/css?family=Roboto:300');
/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
body {
  /*font-family: "Open Sans", sans-serif;*/
  font-family: 'Poppins', sans-serif;
  color: #ffffff;
  background: #000;
  overflow-x: hidden;
  font-size: 13px;
}

a {
  /*color: #e30813;*/
  color:#fff;
}

a:hover {
  color: #fff;
  text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Poppins', sans-serif;
}

*:focus { outline: none !important; }

.container-fluid { padding-left:35px; padding-right: 35px; }
.pos-rel { position: relative; }
.brdr-tp {border-top:1px solid rgba(202,202,202,0.4);  }
.brdr-btm {border-bottom:1px solid rgba(202,202,202,0.4);  }
.btn-shadow { box-shadow: 0px 2px 2px 0px #000; }
.text-shadow { text-shadow: 2px 2px 0px #000; }
.btm-shadow {
  position: relative;
}
.btm-shadow::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -21px;
  background: url('./images/btm-shadow.png') no-repeat left bottom;
  width: 100%;
  height: 22px;
  background-size: 100% 22px;
  z-index: -1;
}
.banner-shadow {
  position: relative;
}
.banner-shadow::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  background: url('./images/banner-shadow.png') no-repeat left bottom;
  width: 100%;
  height: 20px;
  background-size: 100% 22px;
  /*z-index: 9;*/
  z-index: -1;
}

/*--------------------------------------------------------------
# Preloader
--------------------------------------------------------------*/
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  overflow: hidden;
  background: #000;
}

#preloader:before {
  content: "";
  position: fixed;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  border: 6px solid #e30813;
  border-top-color: #000;
  border-bottom-color: #000;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  -webkit-animation: animate-preloader 1s linear infinite;
  animation: animate-preloader 1s linear infinite;
}

@-webkit-keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
# Back to top button
--------------------------------------------------------------*/
.back-to-top {
  position: fixed;
  display: none;
  right: 15px;
  bottom: 70px;
  z-index: 99999;
}

.back-to-top i, .back-to-top svg {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  width: 35px;
  height: 32px;
  border-radius: 100%;
  background: #e30813;
  color: #fff;
  transition: all 0.4s;
  border: 4px solid #e30813;
  padding: 2px;
}

.remove-backtotop {
  display: none;
}

.back-to-top i:hover {
  background: #c40f18;
  color: #fff;
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
#header {
    transition: all 0.5s;
    z-index: 997;
    /* border-bottom: 1px solid rgba(202,202,202,0.4); */
    background: rgba(0,0,0,1);
    padding-top: 4px;    
    padding-left: 20px;
    padding-bottom: 0;    
    padding-right: 20px;
}

.header-position{
  position: fixed;
}


#header.header-scrolled, #header.header-inner-pages {
  background: rgba(0,0,0,1);
}

#header .logo {
  font-size: 28px;
  margin: 0;
  padding: 0;
  line-height: 1;
  font-weight: 700;
  letter-spacing: 0.5px;
}

#header .logo a {
  color: #fff;
}

#header .logo img {
  /*max-height: 40px;*/
  height: 36px;
}

@media (max-width: 992px) {
  #header {
    border: 0;
    padding: 15px 0 0 0;
  }
}


/*--------------------------------------------------------------
# Form
--------------------------------------------------------------*/

.form-container { position: relative;     min-height: 90vh; padding-bottom: 50px; }
.mt-form {
  margin-top: 20vh;
}
.pt-form {
  padding-top: 20vh;  
}
.pb-form {  
  padding-bottom: 20vh;
}

.trans-card {
  background: rgba(0,0,0,.2);
  border:1px solid rgba(0,0,0,.8);
  border-radius: 5px;  
}

.small-form-page {
  width: 100%;  
  background: url("./images/banner-bg.png") bottom center; 
  background-size:cover;
  position: relative;
  background-attachment: fixed;
}

.small-form-page:before {
  content: "";
  background: rgba(0, 0, 0, 0.1);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

.inner-logo { width: 100px; }

.form-body { display: flex; justify-content: center; align-items: center; position: relative; }
.form-content { width: 375px; }

.forms {
  background: rgba(255,255,255,1);
  padding: 15px;
  border-radius: 0px;
  box-shadow:0px 2px 8px rgba(34, 34, 34,0.3);
  color: #333;
}

.form-header {
  display: flex;
  justify-content: center;
  align-items: first baseline;
  margin-bottom: 25px;
}



/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/* Desktop Navigation */
#header .navbar { padding-left: 0; }
.nav-menu ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.nav-menu > ul {
  display: flex;
}

.nav-menu > ul > li {
  position: relative;
  white-space: nowrap;
  margin: 0 12px;
}

.nav-menu a.nav-link {
  display: block;
  position: relative;
  color: rgba(255, 255, 255, 0.7) !important;
  transition: 0.3s;
  font-size: 14px;
  /*padding: 25px 3px;*/
  padding:10px 3px;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
  text-transform: capitalize;
}

.nav-menu > ul > li > a:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #e30813;
  visibility: hidden;
  width: 0px;
  transition: all 0.3s ease-in-out 0s;
}

.nav-menu a.nav-link:hover:before, .nav-menu li:hover > a:before, .nav-menu .active > a.nav-link:before {
  visibility: visible;
  width: 100%;
}

.nav-menu a.nav-link:hover, .nav-menu .active > a, .nav-menu li:hover > a {
  color: #fff;
}

.nav-menu .dropdown-menu {
  padding: 10px 0;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: 0.3s;
  border-top: 2px solid #e30813;
}

.nav-menu .drop-down:hover > ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
}

.nav-menu .drop-down li {
  min-width: 180px;
  position: relative;
}

.nav-menu .dropdown-menu a {
  padding: 8px 15px;
  font-size: 13px;
  font-weight: 400;
  text-transform: none;
  color: #000;
}

.nav-menu .dropdown-menu a:hover, .nav-menu .dropdown-menu .active > a, .nav-menu .dropdown-menu li:hover > a {
  color: #e30813;
}

.nav-menu .drop-down > a:after {
  content: "\f078";
  font-family: "Font Awesome 5 Free Brands", "Font Awesome 5 Free Solid", "Font Awesome 5 Free" !important;
  padding-left: 5px;
}

/*.nav-menu .drop-down .dropdown-menu {
  top: 0;
  left: calc(100% - 30px);
}*/

.nav-menu .drop-down .drop-down:hover > ul {
  opacity: 1;
  top: 0;
  left: 100%;
}

.nav-menu .drop-down .drop-down > a {
  padding-right: 35px;
}

.nav-menu .drop-down .drop-down > a::after {
  content: '';
  background: url('./images/black-right-arrow.svg');
  background-repeat: no-repeat;
  background-size: 100% auto;
  position: absolute;
  right: 15px;
  top: 14px;
  width: 11px;
  height: 12px;  
}

.nav-menu .drop-down .drop-down > a:hover::after {
  content: '';
  background: url('./images/red-right-arrow.svg');
  background-repeat: no-repeat;
  background-size: 100% auto;
  position: absolute;
  right: 15px;
  top: 14px;
  width: 11px;
  height: 12px;
  }

 #header .navbar-light .navbar-toggler {
    color: rgba(0,0,0,.5);
    border-color: rgba(0,0,0,.1);
    background: #e30813;
    padding: 4px 6px;
    /*position: absolute;
    right:15px;
    top:9px;*/
}

@media (max-width: 1366px) {
  /*.nav-menu .drop-down .dropdown-menu {
    left: -90%;
  }*/
  .nav-menu .drop-down .drop-down:hover > ul {
    left: -100%;
  }
}

/* Get Startet Button */
.get-started-btn {
  margin-left: 25px;
  background: #e30813;
  color: #fff;
  border-radius: 2px;
  padding: 6px 25px 7px 25px;
  white-space: nowrap;
  transition: 0.3s;
  font-size: 14px;
  font-weight: 500;
  display: inline-block;
}

.get-started-btn:hover {
  background: #c40f18;
  color: #fff;
}

@media (max-width: 992px) {
  .get-started-btn {
    margin: 0 10px 0 0;
    padding: 6px 18px;
  }
}

/* Mobile Navigation */
.mobile-nav-toggle {
  position: fixed;
  right: 15px;
  top: 18px;
  z-index: 9998;
  border: 0;
  background: none;
  font-size: 24px;
  transition: all 0.4s;
  outline: none !important;
  line-height: 1;
  cursor: pointer;
  text-align: right;
}

.mobile-nav-toggle i, .mobile-nav-toggle svg {
  color: #fff;
}

.mobile-nav {
  position: fixed;
  top: 55px;
  right: 15px;
  bottom: 15px;
  left: 15px;
  z-index: 9999;
  overflow-y: auto;
  background: #fff;
  transition: ease-in-out 0.2s;
  opacity: 0;
  visibility: hidden;
  border-radius: 10px;
  padding: 10px 0;
}

.mobile-nav * {
  margin: 0;
  padding: 0;
  list-style: none;
}

.mobile-nav a {
  display: block;
  position: relative;
  color: #282828;
  padding: 8px 20px;
  font-weight: 400;
  outline: none;
  font-size: 14px;
}

.mobile-nav a:hover, .mobile-nav .active > a, .mobile-nav li:hover > a {
  color: #e30813;
  text-decoration: none;
}

.mobile-nav .drop-down > a:after {
  content: "";
  background: url('./images/black-right-arrow.svg');
  /* font-family: "Font Awesome 5 Free Brands", "Font Awesome 5 Free Solid", "Font Awesome 5 Free" !important; */
  /* padding-left: 10px; */
  position: absolute;
  /* right: 15px; */
  background-repeat: no-repeat;
  background-size: 100% auto;
  /* position: absolute; */
  right: 15px;
  top: 14px;
  width: 11px;
  height: 11px;
  transform: rotate(90deg);
}

.mobile-nav .active.drop-down > a:after {
  content: "";
  background: url('./images/red-right-arrow.svg');
  transform: rotate(-90deg);
}

.mobile-nav .drop-down > a {
  padding-right: 35px;
}

/*.mobile-nav .dropdown-menu {
  display: none;
  overflow: hidden;
}*/

.mobile-nav .drop-down li {
  padding-left: 20px;
}

.mobile-nav-overly {
  width: 100%;
  height: 100%;
  z-index: 9997;
  top: 0;
  left: 0;
  position: fixed;
  background: rgba(15, 15, 15, 0.6);
  overflow: hidden;
  display: none;
  transition: ease-in-out 0.2s;
}

.mobile-nav-active {
  overflow: hidden;
}

.mobile-nav-active .mobile-nav {
  opacity: 1;
  visibility: visible;
}

.mobile-nav-active .mobile-nav-toggle i {
  color: #fff;
}

/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
#hero {
  width: 100%;
  /* height: 90vh; */
  background: url("./images/banner-bg.png") bottom center;
  /*background-size: 100% auto;*/
  background-size:cover;
  position: relative;
}

#hero:before {
  content: "";
  background: rgba(0, 0, 0, 0.1);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

#hero .container {
  /*padding-top: 72px;*/
  position: relative;
  text-align: center;
}

#hero h1 {
  margin: 0 0 10px 0;
  font-size: 38px;
  font-weight: 700;
  line-height: 1;
  color: #fff;

}

#hero h2 {
  color: #eee;
  margin-bottom: 40px;
  font-size: 18px;
  font-weight: 400;
}

#hero .play-btn {
  width: 94px;
  height: 94px;
  margin: 0 auto;
  background: radial-gradient(#e30813 50%, rgba(0, 153, 97, 0.4) 52%);
  border-radius: 50%;
  display: block;
  overflow: hidden;
  position: relative;
}

#hero .play-btn::after {
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-40%) translateY(-50%);
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 15px solid #fff;
  z-index: 100;
  transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

#hero .play-btn::before {
  content: '';
  position: absolute;
  width: 120px;
  height: 120px;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-animation: pulsate-btn 3s;
  animation: pulsate-btn 3s;
  -webkit-animation-direction: forwards;
  animation-direction: forwards;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: steps;
  animation-timing-function: steps;
  opacity: 1;
  border-radius: 50%;
  border: 5px solid rgba(0, 153, 97, 0.7);
  top: -15%;
  left: -15%;
  background: rgba(198, 16, 0, 0);
}

#hero .play-btn:hover::after {
  border-left: 15px solid #e30813;
  transform: scale(20);
}

#hero .play-btn:hover::before {
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-40%) translateY(-50%);
  width: 0;
  height: 0;
  border: none;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 15px solid #fff;
  z-index: 200;
  -webkit-animation: none;
  animation: none;
  border-radius: 0;
}

@media (min-width: 1024px) {
  #hero {
    background-attachment: fixed;
  }

  .banner-logo1 {
    width: 215px;
  }
}

@media (max-width: 768px) {
  #hero h1 {
    font-size: 28px;
    line-height: 36px;
  }
  #hero h2 {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 30px;
  }
}

@-webkit-keyframes pulsate-btn {
  0% {
    transform: scale(0.6, 0.6);
    opacity: 1;
  }
  100% {
    transform: scale(1, 1);
    opacity: 0;
  }
}

@keyframes pulsate-btn {
  0% {
    transform: scale(0.6, 0.6);
    opacity: 1;
  }
  100% {
    transform: scale(1, 1);
    opacity: 0;
  }
}

/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
section {
  padding: 80px 0;
  overflow: hidden;
}

.section-bg {
  background-color: #8fffd6;
}

.section-title {
  padding-bottom: 30px;
}

.section-title h2 {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
}

.section-title h2::after {
  content: '';
  position: absolute;
  display: block;
  width: 50px;
  height: 3px;
  background: #e30813;
  bottom: 0;
  left: 0;
}

.section-title p {
  margin-bottom: 0;
  color: #777777;
  font-size: 15px;
}


/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
#footer {
  background: #161616 url('./images/footer-icon-bg.png') no-repeat center center;
  background-size:cover;
  color: #fff;
  font-size: 14px;
  text-align: center;
  padding: 0;
  position: relative;
}
#footer:before {
  position: absolute;
  bottom:0;
  left:0;
  content: '';
  width: 100%;
  height: 100%;
  background: url('./images/seat-bg.png') no-repeat center bottom;
  background-size: 100% auto;

}


.copyright { background: rgba(0,0,0,1); /*border-top:1px solid rgba(202,202,202,0.4);*/ position: relative; padding:0 0 20px 0; position: relative; }
.copyright::after {
  content: '';
  position: absolute;
  left: 0;
  top: -22px;
  background: url('./images/btm-tp-shadow.png') no-repeat left bottom;
  width: 100%;
  height: 22px;
  background-size: 100% 22px;
  /*z-index: 9;*/
  z-index: -1px;
}
.cpr-section { display: flex; justify-content: space-between; align-items: center; font-size:13px; }
.cpr-section p { color:rgba(255,255,255,.7); }
.cr-links a { color:rgba(255,255,255,.7); padding:0 5px; margin:0 5px; position: relative; white-space: nowrap; display: inline-block;}
.cr-links a:hover { color:rgba(255,255,255,1); }
.cr-links a::before {content: '/'; position: absolute; top: 0; left: 100%; color: rgba(255,255,255,.7); width: 10px; height: 14px; font-size: 13px; }
.cr-links a:last-child:before { display: none; }

/*--------------------------------------------------------------
# Banner
--------------------------------------------------------------*/
/* .banner-content {
  position: absolute;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 99;
  padding-bottom: 75px;
} */
/* .banner-content {
  position: absolute;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 99;
  padding-bottom: 20px;
  padding-top: 80px;
  background: rgba(0, 0, 0, .4);
  } */
.banner-logo1 { width: 250px; }
.banner-logo { width: 250px; }
.banner-content {
    position: absolute;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 99;
    padding-bottom: 30px;
    padding-top: 80px;
}

.blue-big-btn {
  background: #1b6ed8;
  font-size: 22px;
  font-weight: 700;
  font-family: 'Poppins', sans-serif;
  color: #fff;
  border-radius: 2px;
  display: inline-block;
  padding: 1.5% 4.5%;
  position: relative;
  -webkit-transform: perspective(1px) translateZ(0);
     -moz-transform: perspective(1px) translateZ(0);
      -ms-transform: perspective(1px) translateZ(0);
       -o-transform: perspective(1px) translateZ(0);
          transform: perspective(1px) translateZ(0);
}
.blue-big-btn::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #e30813;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: 0 50%;
  transform-origin: 0 50%;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.blue-big-btn:hover::before{
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

.try-btn span { display: inline-block; margin-right: 15px; }
.try-btn svg { display: inline-block; }
/* .poster {
  position: relative;
  padding: 120px 0 45px 0;
  display: flex; justify-content: center; align-items: center;
} */

/* .poster {
  position: relative;
  padding: 0 0 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 65vh;
  background: url('./images/poster-banner2.png');
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 48px;
  background-position: center;
} */

.poster {
  position: relative;
  padding: 0 0 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 94vh;
  background: url('./images/VAI_WebsiteCover.png');
  background-repeat: no-repeat;
  background-size: 100% 55%;
  margin-top: 48px;
  background-position: top center;
  /* background-size: 100% auto; */
  }

.poster::before {
  content: '';
  background: url('./images/graphic-left.png') no-repeat left top;
  background-size: 100% 100%;
  position: absolute;
  left: 0;
  top: 0;
  width: 300px;
  height: 100%;
}
.poster::after {
  content: '';
  background: url('./images/graphic-right.png') no-repeat left top;
  background-size: 100% 100%;
  position: absolute;
  right: 0;
  top: 0;
  width: 300px;
  height: 100%;
}
.poster img { width: 90%; height: auto; /*margin-top: 14%;*/ }



.arrows {
  width: 100%;
  height: 40px; 
  margin-top: 28px;
}

.arrows div {
  stroke: #2994D1;
  fill: transparent;
  stroke-width: 1px;  
  animation: arrow 2s infinite;
  -webkit-animation: arrow 2s infinite; 
  margin-top:-12px;
}

@keyframes arrow
{
0% {opacity:0}
40% {opacity:1}
80% {opacity:0}
100% {opacity:0}
}

@-webkit-keyframes arrow /*Safari and Chrome*/
{
0% {opacity:0}
40% {opacity:1}
80% {opacity:0}
100% {opacity:0}
}

.arrows .a1 {
  animation-delay:-1s;
  -webkit-animation-delay:-1s; /* Safari 和 Chrome */
}

.arrows .a2 {
  animation-delay:-0.5s;
  -webkit-animation-delay:-0.5s; /* Safari 和 Chrome */
}

.arrows .a3 { 
  animation-delay:0s;
  -webkit-animation-delay:0s; /* Safari 和 Chrome */
}


/*--------------------------------------------------------------
# Try It Now
--------------------------------------------------------------*/
.tryIt-now h3 { font-size:42px; font-weight: 700; }
.tryIt-now p { font-size: 15px; }
.email-box input { background: #fff; font-size: 16px; height: 48px; }
.email-box button { background: #1b6ed8; border-color:#1b6ed8; color:#fff; font-size: 20px; font-weight: 700; font-family: 'Poppins', sans-serif;}
.email-box button:hover { background: #105dbe; border-color:#105dbe; }

/*--------------------------------------------------------------
# Page Carousel
--------------------------------------------------------------*/
.page-carousel h2 {font-size: 24px; font-weight: 700; display: inline-block; padding-right: 20px; }
.page-carousel h2::after {position: absolute; left: 100%; top: 0; content: ''; background: url('./images/carousel-heading-line.png') no-repeat left center; width: 100px; height: 100%; display: flex; align-items: center; justify-content: center; }
.page-carousel .item a {  }
/*.page-carousel .item h4 { font-size: 14px; font-weight: 400; background: #232323; padding:15px; margin-bottom: 0; }*/

.page-carousel .item h4 {
  font-size: 14px; font-weight: 400; background: #232323; padding:15px; margin-bottom: 0; 
  position: relative;
  -webkit-transform: perspective(1px) translateZ(0);
     -moz-transform: perspective(1px) translateZ(0);
      -ms-transform: perspective(1px) translateZ(0);
       -o-transform: perspective(1px) translateZ(0);
          transform: perspective(1px) translateZ(0);
}
.page-carousel .item h4::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #e30813;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: 0 50%;
  transform-origin: 0 50%;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.page-carousel .item:hover h4::before{
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}


.page-carousel .owl-dots { display: none; }
.page-carousel .owl-carousel .owl-nav {
    display: block;
}
.page-carousel:hover .owl-carousel .owl-nav button {
  opacity: 1;
  visibility: visible;
}
.page-carousel .owl-carousel .owl-nav button {
  position: absolute;
  background: #ed3e45;
  border-radius: 100%;
  color: #fff;
  height: 32px;
  top: 50%;
  transform: translatey(-50%);
  width: 32px;
  text-align: center;
  line-height: 32px;
  left: -14px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  opacity: 0;
  visibility: hidden;
  font-size: 44px;
}
.page-carousel .owl-carousel .owl-nav button.owl-next {
  right: -14px;
  left: auto;
}
.page-carousel .owl-carousel .owl-nav button span {
  display: inline-block;
  line-height: 21px;
  position: absolute;
  top: 3px;
  left: 11px;
}
.page-carousel .owl-carousel .owl-nav button.owl-prev span { left:9px; }


.page-carousel .owl-carousel .owl-nav button:hover {
  background: #000;
  color:#fff;
}
body { overflow-x:hidden;  }
.page-carousel .owl-carousel .owl-stage-outer { overflow:visible; }
.page-carousel .owl-carousel.owl-drag .owl-item:hover { z-index: 99; }
.page-carousel .owl-carousel { z-index: 99; }
.paid-label {
  background: #ed3e45;
  color: #fff;
  position: absolute;
  right: 0;
  top: 0;
  padding: 5px 10px;
  font-size: 13px;
  font-weight: 500;
}

.paid-red-label {
  color: #ed3e45 !important;
} 


.mb-n-20 { margin-bottom: -20px; }

/* .grow {
  padding: 5px 5px 5px 5px;
  border-radius: 10px;
  height: 49px;
  width: 22%;
  margin: 5px 1% 5px 1%;
  float: left;
  position: relative;
  transition: height 0.5s;
  -webkit-transition: height 0.5s;
  text-align: center;
  overflow: hidden;
} 
.grow:hover {
  height: 145px;
} */

.react-multi-carousel-list { overflow: visible; z-index: inherit; }
.react-multi-carousel-list:hover {
z-index: 9;
}
/* .react-multi-carousel-list ul li { width: 309px !important; } */
.react-multi-carousel-list li .item {  }
.react-multi-carousel-item{
  margin:0 4px;          
}
.sub-item {
  -webkit-transition: all .25s;
     -moz-transition: all .25s;
      -ms-transition: all .25s;
       -o-transition: all .25s;
          transition: all .25s;
          position: absolute;
          top: 0;
          left: 0;
  /* margin:0 4px;           */
 }
.hide-item { display: none; }
.sub-item:hover .hide-item {
  display: block;  
  background: #232323;
  padding:15px;
  position: relative;
}

.sub-item:hover .hide-item:before {
  content: "";
  height: 45px;
  width: 100%;
  position: absolute;
  top: -44px;
  left: 0;
  background: url('./images/grediant-bg.png') repeat-x top center; }

.sub-item:hover { 
  /*height: 150px;*/
   /*bottom:0; */
   /*z-index: 99;*/
   width: 100%;
   top:-20px;
    position: absolute; 
    box-shadow: 0 9px 9px rgba(0,0,0,.7);

-webkit-transform: scale(1.03, 1.03);
-moz-transform: scale(1.03, 1.03);
-ms-transform: scale(1.03, 1.03);
-o-transform: scale(1.03, 1.03);
transform: scale(1.03, 1.03);

  -webkit-transition: all .25s;
     -moz-transition: all .25s;
      -ms-transition: all .25s;
       -o-transition: all .25s;
          transition: all .25s;
          z-index: 999;

      }

.sub-item .movie-poster img {
  height: 155px;
  width: 100%;
  /* object-fit: cover;
  object-position: top center; */
  border-radius: 3px;
}

.movie-poster img {
  height: 155px;
  width: 100%;
  /* object-fit: cover;
  object-position: top center; */
  border-radius: 3px;
}

.video-title {
  font-size: 14px;
  display: block;
  font-weight: 600;
  white-space: normal;
  margin-bottom: 4px;
}

.video-t{
  font-size: 15px;
  font-weight: 700;
  white-space: normal;
  word-break: break-all;
}


.video-play { display: flex; justify-content: flex-start; align-items: center; }
.hide-item a:hover { color:#e30813; }
.video-des {
  color: #f2f4f6;
  font-size: 12px;
  line-height: 1.4;
  /* margin-bottom: 10px; */
}

/* .video-display-f{
  display: flex;
} */

.play-text{
  margin-bottom: -5px;
  display: block;
  font-size: 13px !important;
}

.subscribers{
  height: 20px;
    min-width: 22px;
    font-size: 13px;
    line-height: 20px;
    font-weight: 700;
    padding: 0 4px;
    text-align: center;
    vertical-align: middle;
    color: #8197a4;
    border: 1px solid rgba(129,151,164,.7);
    border-radius: 2px;
}

.contact-btn{
  border:none
}

.contact-title{
  font-size: 14px;
  font-weight: 400;
  padding: 15px;
  margin-bottom: 0;
}

.page-min-h{
  min-height: 90vh;
}

.play-icon{
  cursor: pointer;
    width: 36px !important;
    height: 36px;
}

.video-duration{
  margin-right: 12px;
  font-size: 12px;
    color: #8197a4;
}

.video-display {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  margin-top: -13px;
}
.video-display svg { width: 85px; }

.video-time {
  display: flex;
  width: 100%;
  justify-content: start;
  align-items: center;
}

.play-icon-margin{
  margin-right: 10px;
  font-size: 35px;
}

.video-play {
  display: flex;
}

.video-play span{
  font-size: 15px;
  font-weight: bold;
}

.video-con{
  display: flex;
    align-items: center;
    line-height: normal;
}

.play{
  font-size: 15px;
    font-weight: 700;
}

/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/

.img-center {
  display: flex;
  margin: auto auto 8px;
}

.profile-image{
  background-size: 100% 100%;
  height: 100px;
  width: 100px;
  border-radius: 50%;
  background-color: #425265;
    background-repeat: no-repeat;
    background-position: 50%;
    /* background-image: url('./images/adult-3.png') ; */
}

.rem-psw { display: flex; justify-content: space-between; align-items: center; margin-bottom: 20px; font-size: 12px; }
.rem-psw { font-size: 12px; }
.rem-psw label { margin:0; }
.rem-psw .PrivateSwitchBase-root-1 { padding:0 5px 0 0; }
.rem-psw .MuiTypography-body1 { font-size: 12px; }
.rem-psw .MuiCheckbox-root { color:#fff; }
.rem-psw .MuiCheckbox-colorSecondary.Mui-checked { color: #e30813;  }
.footer-text { text-align: center; font-size: 12px; color:#8197a4 }
.footer-text a { text-decoration: underline; }
.footer-text a:hover { text-decoration: none; }
.trans-card .form-control { font-size: 13px; height: 38px; }
.trans-card .input-group > .form-control:not(:last-child), .trans-card .input-group > .custom-select:not(:last-child) {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.remember input[type=checkbox].css-checkbox {
  position: absolute;
  z-index: -1000;
  left: -1000px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}

.remember input[type=checkbox].css-checkbox + label.css-label {
  padding-left: 22px;
  height: 15px;
  display: inline-block;
  line-height: 15px;
  background-repeat: no-repeat;
  background-position: 0 0;
  font-size: 13px;
  vertical-align: middle;
  cursor: pointer;
  margin-bottom: 0;
  color:#fff;
}

.remember input[type=checkbox].css-checkbox:checked + label.css-label {
  background-position: 0;
  background-image: url('./images/fill-check.png');
}

.remember label.css-label {  
  
  background-image: url('./images/blank-check.png');
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.tc-heading h2 { font-size: 20px; text-align: center; color:#fff; margin-bottom: 10px;  font-weight: 600;}
.inner-page-h { min-height: calc(100vh - 40px); }

.btn-red {  
  background: #e30813;
  color: #fff;
  border-radius: 0.25rem;
  padding: 6px 25px 7px 25px;
  white-space: nowrap;
  transition: 0.3s;
  font-size: 14px;
  font-weight: 500;
  display: inline-block;
 }
 .btn-red:hover { opacity: .8; color:#fff; }

/* 
 .MuiRadio-root{
  color:#fff;
 } */

 .plan-container{
  display: flex;
 }

 .planGrid__featureTableRow{
  /* border-bottom: 1px solid #ccc; */
 }

.search-sec {
  width: 250px;
   margin:0 15px;
}
.search-sec input { font-size: 14px; background:rgba(0,0,0,.5); color:#ddd; border-color:rgba(255,255,255,.2); border-radius: 5px 0 0 5px; padding-left: 15px !important;}
/*.MuiInput-underline:hover:not(.Mui-disabled):before {
border-bottom:2px solid #fff !important;
}*/
.MuiInput-underline:after { border:none !important; }
.search-sec input:focus {  box-shadow: none; color:#fff; }
.search-sec button { 
  /* background:rgba(227,8,19,1);  */
  color:#fff; border-color:rgba(227,8,19,1); /*border-radius: 0 5px 5px 0;*/ border-radius: 0; }
  .search-sec .search-box{
    border: 1px solid #555;
    width: 100%;
    /*width:220px*/
  }
.user-profile-view { display: flex; justify-content: center; align-items: center; }
.user-profile-view .dropdown-toggle::after { display: none; }

/*Notify Sec*/
.notify-sec .dropdown .notification {
  width: 350px;
  padding: 0;
  line-height: 1.4;
  overflow: hidden;
}

.notify-sec .dropdown .notification .noti-head {
  background: #3b4650;
  padding: 15px 20px;
}

.notify-sec .dropdown .notification {
    width: 350px;
    padding: 0;
    line-height: 1.4;
    overflow: hidden;
}

.notify-sec .dropdown .notification a {
    color: #222;
}

.notify-sec .dropdown .notification a:hover {
    color: #222;
}

.notify-sec .dropdown .notification .noti-head {
    background: #3b4650;
    padding: 15px 20px;
}

.notify-sec .dropdown .notification .noti-head h6 {
    color: #fff;
}

.notify-sec .dropdown .notification .noti-head a {
    color: #fff;
    text-decoration: underline;
    font-size: 13px;
}

.notify-sec .dropdown .notification .noti-body {
  padding: 0;
  position: relative;
  height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
}

.notify-sec .dropdown .notification .noti-body img {
    width: 40px;
    margin-right: 20px;
}

.notify-sec .dropdown .notification .noti-body li {
    padding: 15px 20px;
    -webkit-transition: all 0.15s ease-in-out;
    transition: all 0.15s ease-in-out;
}

.notify-sec .dropdown .notification .noti-body li.n-title {
    padding-bottom: 0;
}

.notify-sec .dropdown .notification .noti-body li.n-title p {
    margin-bottom: 5px;
}

.notify-sec .dropdown .notification .noti-body li.notification:hover {
    background: rgba(64, 153, 255, 0.1);
}

.notify-sec .dropdown .notification .noti-body li p {
    margin-bottom: 5px;
    font-size: 13px;
}

.notify-sec .dropdown .notification .noti-body li p strong {
    color: #222;
}

.notify-sec .dropdown .notification .noti-body li .n-time {
    font-size: 80%;
    float: right;
}

.notify-sec .dropdown .notification .noti-footer {
    border-top: 1px solid #f1f1f1;
    padding: 15px 20px;
    text-align: center;
    background: #f6f7fb;
}

.notify-sec .dropdown .notification .noti-footer a {
    text-decoration: underline;
    font-size: 13px;
}

.notify-sec .dropdown .notification ul {
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.notify-sec .dropdown .notification ul li {
    padding: 20px 15px;
}

/*.ps {
  overflow: hidden !important;
  overflow-anchor: none;
  -ms-overflow-style: none;
  touch-action: auto;
  -ms-touch-action: auto;
}*/

.notify-sec .dropdown-toggle .badge, .notify-sec .badge {
  border-radius: 100px;
  right: -8px;
  position: absolute;
  top: -9px;
  padding: 3px;
}
/*//Notify Sec*/


/*Profile Sec*/
 .profile-sec { display: flex; justify-content: center; align-items: center; }
 .profile-sec .dropdown-toggle { width: 30px; height: 30px; display: flex; justify-content: center; align-items: center; margin-right: 20px; }
 .profile-sec .dropdown-toggle i { font-size: 25px; }
.profile-sec.dropdown .dropdown-toggle {
  /*line-height: 56px;*/
  display: inline-block;
  padding-right: 5px;
}
.img-radius { border-radius: 50%; }
.profile-sec.dropdown .dropdown-toggle img {
    border: 2px solid #fff;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    object-fit: cover;
    object-position: center top;
}
.wid-40 {
    width: 40px;
}

.profile-sec .dropdown-menu {
    color: #222;
}
.profile-sec.dropdown .profile-notification {
    padding: 0;
    line-height: 1.4;
    overflow: hidden;
}
.profile-sec.dropdown .dropdown-menu {
    position: absolute;
    min-width: 400px;
    background:#000;
}
.profile-sec.dropdown .profile-notification .pro-head {
    color: #fff;
    background: #f96921;
}
.profile-sec.dropdown .profile-notification .pro-head {
    color: #fff;
    padding: 15px;
    position: relative;
    background: #3b4650;
}
.profile-sec.dropdown .profile-notification .pro-head img {
    width: 40px;
    margin-right: 10px;
    border-radius: 50%;
    height: 40px;
    object-fit: cover;
    object-position: center top;
    border: 2px solid #fff;
}
.profile-sec.dropdown .profile-notification .pro-head .dud-logout {
  color: #fff;
  padding-right: 0;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 40px;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
}

.profile-sec.dropdown .profile-notification .pro-body {
    padding: 20px 0;
    display: flex;
    margin-bottom: 0;
    list-style: none;
    flex-wrap: wrap;
    flex-direction: row;
    background-color: #000;
    width: 100%
}
.profile-sec.dropdown .profile-notification .pro-body li {
  /* margin: 0 10px; */
  border-radius: 2px;
  /* width: 44%; */
}
.profile-sec.dropdown .profile-notification .pro-body li a {
  color:#999998;
  font-size: 14px;
  padding: 10px;
}
.profile-sec.dropdown .profile-notification .pro-body li a svg {
  margin-right: 10px;
}
.profile-sec.dropdown .profile-notification .pro-body li.active > a, .profile-sec.dropdown .profile-notification .pro-body li:active > a, .profile-sec.dropdown .profile-notification .pro-body li:focus > a, .profile-sec.dropdown .profile-notification .pro-body li:hover > a {
  color: #fff;
  background: transparent;
}
.profile-sec.dropdown .profile-notification .pro-body li.active, .profile-sec.dropdown .profile-notification .pro-body li:active, .profile-sec.dropdown .profile-notification .pro-body li:focus, .profile-sec.dropdown .profile-notification .pro-body li:hover {
  /* background: #4099ff; */
  /* background: #e30813; */
  /* box-shadow: 0 15px 8px -9px rgba(0, 0, 0, 0.25); */
}

/*//Profile Sec*/

.inner-bnr {
  width: 100%;
  /*background: url(/static/media/banner-bg.0dfc8115.png) bottom;
  background-size: cover;
  background-attachment: fixed;*/
  position: relative;  
  height: 110px;
  background: #0c0c0c;
}
.inner-page-heading { 
  display: flex; 
  justify-content: center; 
  align-items: center; 
  height: 100%; 
  flex-direction: column; 
}

.inner-page-heading h2 { font-size: 28px; }
.inner-page-heading p { font-size: 14px; text-align: center; }

.small-text{
  font-size: 13px;
  font-weight: 500;
  color: #8197a4;
}
.profile-container{
  /* width: 320px; */
  /*margin: 35px auto;*/
  display: flex;
  justify-content: space-between;
} 

.user-container{
  padding: 2px 16px 0;
    background: none;
    border: none;
}
.user-title{
  color: #fff;
  margin-bottom: -6px;
}

.action{
  margin-right: 8px;
    background-color: #e30813;
    color: #fff;
    border: none;
    border-radius: 100%;
    width: 25px;
    height: 25px;
    text-align: center;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    font-size: 12px;
}

.tc-heading {display: flex; width: 100%; justify-content: center; align-items: center; position: relative; }
.users-box { display: flex; justify-content: center; align-items: center; flex-direction: column; height: 100% }
.user-box-highlight:hover{
  border: 1px solid #555;
}
.user-title {display: flex; justify-content: center; align-items: center; font-weight: 600; text-transform: uppercase; }
.add-new-profile { position: absolute; right:0; top:0; font-size: 12px; background:#555; border-radius: 5px; padding:5px 10px; }
.back-link { position: absolute; left:0; top:0; font-size: 12px; background:#555; border-radius: 5px; padding:5px 10px; }
.inner-back-link { left:0; top:0; font-size: 12px; background:#555; border-radius: 5px; padding:5px 10px; }
.profile-btns { display: flex; justify-content: space-between;}
.add-new-profile:hover, .back-link:hover { text-decoration: none; opacity: .8; }
.users-box-btns {  }

.red-btn {  
  background: #e30813;
  color: #fff;
  border-radius: 2px;
  padding: 5px 20px 5px 20px;
  white-space: nowrap;
  transition: 0.3s;
  font-size: 13px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  /*display: inline-block;*/
}
.red-btn svg { font-size: 16px; margin-right: 5px; }

.red-btn:hover {
  background: #c40f18;
  color: #fff;
}

.dark-btn {  
  background: #425265;
  color: #fff;
  border-radius: 2px;
  padding: 5px 20px 5px 20px;
  white-space: nowrap;
  transition: 0.3s;
  font-size: 13px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  /*display: inline-block;*/
}
.dark-btn svg { font-size: 16px; margin-right: 5px; }

.dark-btn:hover {
  background: #566b84;
  color: #fff;
}

.up-file {position: relative; overflow: hidden;  cursor: pointer; display: flex; justify-content: center; align-items: center; }
.up-file svg { margin-right: 8px; }
.up-file input {position: absolute; font-size: 50px; opacity: 0; right: 0; top: 0; }

.remove-profile {
  font-size: 15px;
  cursor: pointer;
}

.text-center .remove-profile{
  color: #79b8f3
}

.text-center .remove-profile:hover{
  color: #79b8f3
}
.profile-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.profile-container span.MuiSwitch-root { margin-top: -10px; }
.kids-switch .MuiSwitch-track { background: rgba(255,255,255,.6); }
.kids-switch .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track { background: #4CAF50; }
.kids-switch .MuiSwitch-colorSecondary.Mui-checked { color: #4CAF50; }
.mt-25 { margin-top: 25px; }

.learn-more{
  display: inline-flex;
}
.player-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  background: black;
}
 
.react-player {
  width: 100%;
  height: 100%;
}

.video-header .MuiAppBar-colorPrimary {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: none;
}

.profile-image img{
  object-fit: cover;
  object-position: center top;
  border: 2px solid #fff;
}

.search-container{
  /* display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap; */
  display: grid;
  grid-gap: 12px;
  grid-auto-flow: dense;
  grid-template-columns: repeat(auto-fill,minmax(330px,1fr));
  padding-right: 0;
}

.search-container .item{
  /* width:299px;
  margin-left: 10px;
  margin-right: 10px; */
  /* width:362px; */
  position: relative;
  /* margin-bottom: 20px; */
  margin-bottom: 50px;
}

/* .search-container .item:last-child .sub-item:hover, .search-container .item:nth-last-child(2) .sub-item:hover, .search-container .item:nth-last-child(3) .sub-item:hover, .search-container .item:nth-last-child(4) .sub-item:hover {
  top: -83%;
} */

.search-container .item { overflow:visible; z-index: inherit; }
.search-container .item:hover {
z-index: 9;
}

.search-container .item .sub-item{
  margin: 0
}

.search-container .item .sub-item:hover {
  width: 100%
}

.search-container .item .sub-item .movie-poster img {
  /* height: 200px; */
  height:155px;
  border-radius: 0;
}

.list-group-item{
  color:#000
}


.plan-sec input[type="radio"] { display: none; }
.plan-sec:hover { cursor: pointer; }
.planActive .trans-card { 
  /*background: rgba(227,8,19,.5); */
  background: #fff;
  color:#333;
}
.plan-sec .lead { text-align: center; padding-bottom: 10px; border-bottom: 1px solid rgba(0,0,0,.5); font-weight: 600; }
.plan-sec strong { font-weight: 600; }


.inner-pt { padding-top: 75px; }
.white-box { border-radius: 5px; background: rgba(255,255,255,1); color:#555; }
.ac-setting { display: flex; justify-content: space-between; align-items: center; }
.ac-setting h2 { font-size: 20px; font-weight: 600; color:#000; }
.ac-setting p {margin-bottom: 0; }
.notify-box .notify-content:last-child { border-bottom: none; }

.notify-card {
  background: #232323;
  border:1px solid rgba(0,0,0,.8);
  border-radius: 5px;  
}

.notify-content { display: flex;
  justify-content: space-between;}
.notify-content a { color:#fff; font-size: 16px; font-weight: 600;}
.notify-right span{
  font-size: 11px;
  color: rgb(129, 151, 164);
}
.notify-left{
  width: 30%;
}

.notify-left{
  width: 80%;
}
.notify-left p{
  font-size: 12px;
}
.react-confirm-alert-body > h1{
  font-size: 19px;
}
.react-confirm-alert-overlay{
  backdrop-filter: blur(5px) grayscale(100%);
  background-color: rgba(0,0,0,.5);
}

.password-trigger{
  position: absolute;
  /* top: 20%; */
  /* right: .5rem; */
  right:0;
  /* color: #666; */
  z-index: 9999;
  /* background: rgba(0,0,0,.3); */
  padding: 9px;
}
.password-trigger a{
  color: #000
}
.profile-dropdown-item {
  background: none;
  border: none;
}

.profile-dropdown-item span {
  margin-left: 8px;
}


.dropdown-right-item{
  border-left: 1px solid #414c53;
  width: 50%
}

.dropdown-left-item{
  width: 50%
}

.dropdown-left-item li a{
  padding-left: 20px !important;
}

.user-adult-title{
  font-size: 10px;
  color: #8197a4;
}

.see-more{
  display: flex;
  justify-content: space-between;
}

.see-more-text{
  /* color: #79b8f3; */
  font-size: 13px;
}

/* .category-video-container .item{
  width: 299px
} */

/*Video Detail*/
.video-detail { display: flex; }
.video-detail-banner { min-height:65vh; width: 100%; position: relative; background-attachment: fixed; background-size: cover; background-repeat: no-repeat; overflow-x: hidden; }
.video-detail-banner::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: -200px;
  left: 0;
  /*z-index: -1;*/
  background: -webkit-gradient(linear,left top,right top,from(#171717),color-stop(20%,rgba(23,23,23,.98)),color-stop(25%,rgba(23,23,23,.97)),color-stop(35%,rgba(23,23,23,.95)),color-stop(40%,rgba(23,23,23,.94)),color-stop(45%,rgba(23,23,23,.92)),color-stop(50%,rgba(23,23,23,.9)),color-stop(55%,rgba(23,23,23,.87)),color-stop(60%,rgba(23,23,23,.82)),color-stop(65%,rgba(23,23,23,.75)),color-stop(70%,rgba(23,23,23,.63)),color-stop(75%,rgba(23,23,23,.45)),color-stop(80%,rgba(23,23,23,.27)),color-stop(85%,rgba(23,23,23,.15)),color-stop(90%,rgba(23,23,23,.08)),color-stop(95%,rgba(23,23,23,.03)),to(rgba(23,23,23,0)));
  background: -webkit-linear-gradient(left,#171717 0,rgba(23,23,23,.98) 20%,rgba(23,23,23,.97) 25%,rgba(23,23,23,.95) 35%,rgba(23,23,23,.94) 40%,rgba(23,23,23,.92) 45%,rgba(23,23,23,.9) 50%,rgba(23,23,23,.87) 55%,rgba(23,23,23,.82) 60%,rgba(23,23,23,.75) 65%,rgba(23,23,23,.63) 70%,rgba(23,23,23,.45) 75%,rgba(23,23,23,.27) 80%,rgba(23,23,23,.15) 85%,rgba(23,23,23,.08) 90%,rgba(23,23,23,.03) 95%,rgba(23,23,23,0) 100%);
  background: -moz- oldlinear-gradient(left,#171717 0,rgba(23,23,23,.98) 20%,rgba(23,23,23,.97) 25%,rgba(23,23,23,.95) 35%,rgba(23,23,23,.94) 40%,rgba(23,23,23,.92) 45%,rgba(23,23,23,.9) 50%,rgba(23,23,23,.87) 55%,rgba(23,23,23,.82) 60%,rgba(23,23,23,.75) 65%,rgba(23,23,23,.63) 70%,rgba(23,23,23,.45) 75%,rgba(23,23,23,.27) 80%,rgba(23,23,23,.15) 85%,rgba(23,23,23,.08) 90%,rgba(23,23,23,.03) 95%,rgba(23,23,23,0) 100%);
  background: -o-linear-gradient(left,#171717 0,rgba(23,23,23,.98) 20%,rgba(23,23,23,.97) 25%,rgba(23,23,23,.95) 35%,rgba(23,23,23,.94) 40%,rgba(23,23,23,.92) 45%,rgba(23,23,23,.9) 50%,rgba(23,23,23,.87) 55%,rgba(23,23,23,.82) 60%,rgba(23,23,23,.75) 65%,rgba(23,23,23,.63) 70%,rgba(23,23,23,.45) 75%,rgba(23,23,23,.27) 80%,rgba(23,23,23,.15) 85%,rgba(23,23,23,.08) 90%,rgba(23,23,23,.03) 95%,rgba(23,23,23,0) 100%);
  background: linear-gradient(to right,#171717 0,rgba(23,23,23,.98) 20%,rgba(23,23,23,.97) 25%,rgba(23,23,23,.95) 35%,rgba(23,23,23,.94) 40%,rgba(23,23,23,.92) 45%,rgba(23,23,23,.9) 50%,rgba(23,23,23,.87) 55%,rgba(23,23,23,.82) 60%,rgba(23,23,23,.75) 65%,rgba(23,23,23,.63) 70%,rgba(23,23,23,.45) 75%,rgba(23,23,23,.27) 80%,rgba(23,23,23,.15) 85%,rgba(23,23,23,.08) 90%,rgba(23,23,23,.03) 95%,rgba(23,23,23,0) 100%);
}

.video-detail-banner::after {
  position: absolute;
  bottom:0;
  left:0;
  width: 100%;
  content: '';
  height: 30px;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,000000+100&0+0,0.65+100 */
background: -moz-linear-gradient(top,  rgba(0,0,0,0) 0%, rgba(0,0,0,0.65) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 ); /* IE6-9 */
 
}


.vdb-content h1 { font-size: 36px; }
.vdb-content p { font-size: 13px; }
.vdb-detail { display: flex; align-items: center; justify-content: flex-start; }
.vdb-detail span { margin-right: 10px; color:#a3a3a3; border-right: 1px solid #a3a3a3; padding-right: 10px; }
.vdb-detail span:last-child { border:none; }
.rate-bg { background:rgba(0,0,0,.4); padding:1px 10px !important; border-radius: 2px; margin-right:10px; margin:0 !important; border
  :1px solid #a3a3a3 !important; }
.vdb-extra-detail strong { margin-right: 5px; font-weight: 500;}  
.vdb-extra-detail span { color:#a3a3a3; }

.vdb-btns { display: flex; justify-content: flex-start; align-items: center; }
.vdb-btns a { margin-right: 10px; }
.vdb-heading { font-size: 32px; }
.vdb-more-list { display: flex; flex-wrap: wrap; list-style: none; padding:0; }
.vdb-more-list li { width: 30%; margin-bottom: 20px; }
.vdb-more-list li label { color:#a3a3a3; width: 100%; }
.vdb-more-list li span { width: 100%; display: block; }

.rv-sec { display: flex; flex-direction: column;
   /* border-bottom: 1px solid #555; padding-bottom: 10px;  */
  }
.rv-sec img { width: 100%; height: 150px; 
  /* margin-bottom: 15px; */
 }
.rv-sec h4 { font-size: 16px; font-weight: normal; }

.MuiTooltip-tooltip{
  background-color: #555 !important;
  font-size:15px !important
}
/* //Video Detail*/
.renew-plan a{
color: #105dbe;
text-decoration: underline;
}

.list-group-item{
  border: none;
}

.payment-container {
  display: flex; justify-content: flex-start; align-items: flex-start;
}
.payment-container h6 { color:#000; margin-top:5px; margin-bottom: 10px; }
.payment-img{margin-right: 10px; width: 30px; }
.payment-content { width: calc(100% - 30px); }
.feature-list { list-style: none; margin:0; padding:15px 0 0 0; }
.feature-list li { display: flex; margin-bottom: 10px; }
.feature-list li h4 { font-size: 16px; color:#4d4d4d; margin-bottom: 5px;}
.feature-list li > div { width: calc(100% - 25px); }
.feature-list li span { width: 25px; margin-right:15px; }
.white-box {padding: 0.75rem 1.25rem; background-color: #fff; }

.subscription-box { background: #0c0c0c; color:#fff; border:1px solid #4d4d4d; border-radius: 0.25rem; padding:0.75rem 1.25rem;}
.subscription-box p { line-height: 1.4; margin-bottom: 8px; }
.subscription-box i { margin-right: 10px; width: 15px; font-size: 12px; }
.subscription-box span { color:#fff; font-size: 12px; font-weight: 400; margin-left: 5px; }
.subscription-box strong { color:#fff; font-size: 13px; font-weight: 500; margin-right: 5px;}
.subscription-box h6 { color:#ff4d4e; }
.subs-profile h2 { color:#fff; font-size: 20px; margin-bottom: 15px; }

.subs-profile { display:  flex; justify-content: flex-start; align-items: center; }
.subs-profile h2 { margin-bottom: 0; }
.subs-profile img { width: 80px; height: 80px; margin-right: 10px;}

.page-slider .carousel .slide img {
    width: 100%;
    vertical-align: top;
    border: 0;
    height: 45vh !important;
    object-fit: top;
    object-position: center;
}

.video-history-list {
  border-bottom: 1px solid #c4cacf;
}

.video-history-sec { display: flex; flex-direction: column; padding-bottom: 10px; }
.video-history-sec img { width: 80%; height: 120px; }


.vh-section{
  display: flex; justify-content: space-between; background: #232323; padding:15px; border-bottom:1px solid #000; 
  /* margin-bottom: 15px; */
}

.vh-left{
  width: calc(100% - 30px);
  display: flex;
    align-items: center;
}

.vh-right{
  width: 30px;
  margin-right: 10px;
  display: flex;
}

.vh-poster {
  margin-right: 20px;
}

.vh-poster img {
  width: 150px;
  height: 150px;
  object-fit: scale-down;
  object-position: center;
  max-height: 150px;
}

.vh-content h5{
  font-size: 20px;
}

.load-more-btn{
  display: flex;
  justify-content: center;
  margin-top: 15px;
}

/* video detail */
.vd-section{
  display: flex; justify-content: space-between; 
}

.vd-left{
  width: calc(100% - 30px);
  display: flex;
  align-items: center;
}

.vd-poster {
  margin-right: 10px;
}

.vd-poster img {
  width: 100px;
}

.vd-content {
  width: 142px;
}

.wl-empty{
/*  padding-top: 48px;
*/ 
  padding-top: 20vh;
   text-align: center;
/*    padding-bottom: 48px;
*/}

.wl-empty-poster{
  margin-bottom: 30px;
}

.wl-empty-poster img{
  max-width: 217px;
}

.wl-empty p{
  color: #8197a4;
  font-size: 15px;
}

.wl-empty h3{
  font-size: 17px;
  /* font-weight: 700; */
  line-height: 24px;
}

.wl-empty p a{
  color: #79b8f3;
}

.infinite-scroll-component {
  overflow: hidden !important;
}

.active-user a img{
  /* background-color: rgba(0, 153, 97, 0.4); */
  border: 2px solid #fff;
}

.active-user a span{
  /* background-color: rgba(0, 153, 97, 0.4); */
  color: white;
}

/* .user-profile-view{
  margin-right: 20px;
} */

.bell-size{
  font-size: 16px;
}

.signup-success{
  text-align: center;
}

.signup-success p a{
  color: #105dbe;
  text-decoration: underline;
}

.signup-success a{
  width: 250px
}

.pt-signsuccess{
  padding-top: 5vh;
}


/* plan section */

section.plan-section{
padding: 50px 0;
}

.plan-section{
    width: 100%;
    /* height: 100vh; */
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    /* padding: 140px 0; */
    font-family: 'Roboto', sans-serif !important;

}
.plan-section .card{
  position: relative;
  /* max-width: 300px; */
  height: 100%;
  background: linear-gradient(-45deg,#e30813,#000);
  border-radius: 15px;
  margin: 0 auto;
  padding: 40px 20px;
  -webkit-box-shadow: 0 10px 15px rgba(0,0,0,.1) ;
          box-shadow: 0 10px 15px rgba(0,0,0,.1) ;
-webkit-transition: .5s;
transition: .5s;
cursor: pointer

}


/* .card:hover{
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
} */

.planActive .card{
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
          /* z-index: 999; */
}
.col-sm-4:nth-child(1) .plan-section .card ,
.col-sm-4:nth-child(1) .plan-section .card .title .fa{
  background: linear-gradient(-45deg,#000,#e30813);

}
.col-sm-4:nth-child(2) .plan-section .card,
.col-sm-4:nth-child(2) .plan-section .card .title .fa{
  background: linear-gradient(-45deg,#e30813,#000);

}
.col-sm-4:nth-child(3) .plan-section .card,
.col-sm-4:nth-child(3) .plan-section .card .title .fa{
  background: linear-gradient(-45deg,#000,#e30813);
}

.planActive .card::before{
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, .1);
z-index: 1;
/* -webkit-transform: skewY(-5deg) scale(1.5); */
        /* transform: skewY(-5deg) scale(1.5); */
        border-radius: 15px;
        box-shadow: 0 10px 15px #000000 ;
}
.title .fa{
  color:#fff;
  font-size: 45px;
  width: 100px;
  height: 100px;
  border-radius:  50%;
  text-align: center;
  line-height: 100px;
  -webkit-box-shadow: 0 10px 10px rgba(0,0,0,.1) ;
          box-shadow: 0 10px 10px rgba(0,0,0,.1) ;

}
.title h2 {
  position: relative;
  margin: 20px  0 0;
  padding: 0;
  color: #fff;
  font-size: 24px;
 z-index: 2;
}
.price,.option{
  position: relative;
  z-index: 2;
}
.price h4 {
margin: 0;
padding: 15px 0 ;
color: #fff;
font-size: 20px;
}
.option ul {
  margin: 0;
  padding: 0;

}
.option ul li {
margin: 0 0 10px;
padding: 0;
list-style: none;
color: #fff;
font-size: 16px;
}
.plan-section .card a {
  position: relative;
  z-index: 2;
  background: #fff;
  color : black;
  width: 150px;
  height: 40px;
  line-height: 40px;
  border-radius: 40px;
  display: block;
  text-align: center;
  margin: 20px auto 0 ;
  font-size: 16px;
  cursor: pointer;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, .1);
          box-shadow: 0 5px 10px rgba(0, 0, 0, .1);

}
.plan-section .card a:hover{
    text-decoration: none;
}

.plan-sec input[type="radio"] { display: none; }

.rotate-plane{
  transform: rotate(-50deg);
}

.plan-sec-h {
  height: 100%
}

.plan-sec-w {
  width: 100%;
}

.plan-sec{
  margin: 0 auto 30px auto;
}

.text-c{
  text-align: center;
}

/* plan-section end */


/* subscription section */

section.subscription-section{
  padding: 50px 0;
  }
  
  .subscription-section{
      width: 100%;
      /* height: 100vh; */
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      /* padding: 140px 0; */
      font-family: 'Roboto', sans-serif !important;
  
  }
  .subscription-section .card{
    position: relative;
    /* max-width: 300px; */
    height: 100%;
    background: linear-gradient(-45deg,#e30813,#000);
    border-radius: 15px;
    margin: 0 auto;
    padding: 40px 20px;
    -webkit-box-shadow: 0 10px 15px rgba(0,0,0,.1) ;
            box-shadow: 0 10px 15px rgba(0,0,0,.1) ;
  -webkit-transition: .5s;
  transition: .5s;
  }
  
  
  /* .card:hover{
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  } */
  
  .planActive .card{
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
            /* z-index: 999; */
                 
  }

  .planActive-green .card {
    background: linear-gradient(-45deg,green,#000);   
  }
  .col-sm-4:nth-child(1) .subscription-section .card ,
  .col-sm-4:nth-child(1) .subscription-section .card .title .fa{
    background: linear-gradient(-45deg,#000,#e30813);
  
  }
 /* 404 not found page
  } */
  
  .page-error-container{
    width: 100%;  
    background: url("./images/error-bg.svg") bottom center; 
    background-size:cover;
    position: relative;
    background-attachment: fixed;
  }

  .page-error{
    text-align: center;
  }

  .page-error a{
    width: 250px
  }

  .pt-page-error{
    padding-top: 9vh;
  }

  /* continue watching */

  .cw-container{
    margin-right: 8px;
  }

  .cw-container a{
    display: flex;
    align-items: center;
    line-height: 20px;
    padding-right: 40px;
  }

  .cw-container a img{
    margin-right: 12px;
  }

  .cw-text {
    font-size: 15px;
  }

  .cw-progress{
    margin-top: 5px;
  }

  .se-section{
    display: flex;
    justify-content: space-between;
    background: #232323;
    padding: 15px;
    border-bottom: 1px solid #000;
    margin-bottom: 15px;
  }

  .se-left {
    width: calc(100% - 30px);
    display: flex;
}

.heart-icon{
  color: #e30813;
    font-size: 18px;
}

.heart-icon:hover{
  color: #e30813;
}

.se-des{
  color:#a3a3a3
}

/* update plan */

/* section.update-plan-section {
  padding: 0;
} */

.check-circle{
  color: #97cd51;
    font-size: 30px;
}

.payment-content a { color:#fff; font-size: 20px; font-weight: 600;}

.text-sm {
  color: rgb(129, 151, 164);
}

.d-flex {
  display: flex
}

.pb-20 { padding-bottom:20%;}
.pb-10 { padding-bottom:10%;}

.video-des h1, .video-des h2, .video-des h3, .video-des h4, .video-des h5, .video-des h6{
font-size: 12px;
}

.video-des h1::after, .video-des h2::after, .video-des h3::after, .video-des h4::after, .video-des h5::after, .video-des h6::after{
  display: none;
}

.main-height {
  min-height: calc(100vh - 131px);
}

.justify-content-center{
  justify-content: center;
}

.upgrade-plan-box .MuiBackdrop-root{
  background: rgba(0,0,0,0.4);
}

.upgrade-plan-box .MuiDialog-paperWidthSm{
  border: 2px solid #e30813;
  border-radius: 2px;
  color: white;
  background: #232323;
}
.upgrade-plan-box h2 {
  font-size: 18px;
}
.se-des h1, .se-des h2, .se-des h3, .se-des h4, .se-des h5, .se-des h6{
  font-size: 13px;
}

.vh-content p h1, .vh-content p h2, .vh-content p h3, .vh-content p h4, .vh-content p h5, .vh-content p h6{
  font-size: 13px;
}

.vdb-content p h1, .vdb-content p h2, .vdb-content p h3, .vdb-content p h4, .vdb-content p h5, .vdb-content p h6{
  font-size: 13px;
}

#main{
  overflow: hidden;
}

.react-multiple-carousel__arrow--left {
  left: -50px;
}

.react-multiple-carousel__arrow {
  border-radius: 0;
  background: rgba(15,23,30,.5);
  min-width: 60px;
  height: 100%;
}

.react-multiple-carousel__arrow--right{
  right: -50px;
}

#header .navbar-toggler-icon {
  width: 1.1rem;
  height: 1.1rem;
}
/* .react-multi-carousel-list{
  display: block
} */

.carousel .slide a { display:block;}
.page-slider.poster:after, .page-slider.poster:before { display:none }
.inner-back-link{ display: none;}
.navbar-light .navbar-toggler-icon {
    background-image: url('./images/menu-bar.svg');
    background-size: 15px auto;
    display: flex;
    align-items: center;
    justify-content: center;
}


.store-btns { display: flex; align-items: center; justify-content: center; margin-bottom: 15px;}
.store-btns img {
  width: 145px;
  border: 1px solid #e30813;
  border-radius: 5px;
}

.store-btns .producer-btn{
  width: 45px !important;
  border: none !important;
}

.paypal-btns{
  display: flex; align-items: center; justify-content: center; margin-bottom: 15px;
}
.paypal-btns img {
  width: 200px;
  border-radius: 5px;
}

.fb-btn{
  width: 47px !important;
  border: none !important;
}

.footer-content p{
  font-size: 14px !important;
  color: rgba(255,255,255,.7);
}
.footer-content b{
  color: #e30813;
}

/*************************/
/*Responsive start*/
/*************************/

@media(max-width: 1440px) {
  .poster{
    /* background-size: auto 55%; */
  }
}

@media(max-width: 1366px) {

  .sub-item .movie-poster img {
    height: 140px;
  }
   .movie-poster img {
    height: 140px;
  }
  .search-container .item .sub-item .movie-poster img {
    height:150px;
  }
  .banner-logo {
    width: 240px;
  }
  #hero h1 { font-size: 36px; }
  #hero h2 {
    font-size: 16px; 
  }
  .blue-big-btn {
    font-size: 20px;    
    padding:1.3% 4%;
  }

  .page-carousel h2 {
    font-size: 22px;    
  }
  .tryIt-now h3 {
    font-size: 36px;    
  }
  .tryIt-now p {
    font-size: 14px;
  }
  .email-box button {
    font-size: 18px; 
  }
  .vdb-heading {
    font-size: 30px;
}

.pt-page-error {
    padding-top: 19vh;
}

.page-error a {
    width: 200px;
}

.page-error h4 {
  font-size: 14px;
    margin-top: 12px;
}
}

@media(max-width: 1279px) {
  .sub-item .movie-poster img {
    height: 130px;
  }
   .movie-poster img {
    height: 130px;
  }
  .search-container{
    grid-gap: 4px;
    grid-template-columns: repeat(auto-fill,minmax(197px,1fr));
  }

  .search-container .item .sub-item .movie-poster img {
    /* height: 200px; */
    height:130px;
  }


  
  #header .logo img { 
    height: 32px;
  }
  .banner-logo {
    width: 235px;
  }
  #hero h2 { 
    padding: 0 10%;
  }
  .banner-content { 
    /* top:30px; */
    top:0
  }
  
  .poster img { width: 100%; }
  #hero h1 {
    font-size: 32px;
  }
  .blue-big-btn {
    font-size: 18px;
    padding: 1% 3%;
  }
  .arrows { margin-top:16px; }
  .arrows div img { width: 25px; }
  .page-carousel h2 {
  font-size: 20px;
}
.page-carousel h2::after {
  width: 75%;
  background: none;
}
.nav-menu a.nav-link {
  font-size: 13px;
  font-weight: 400;
  text-transform: capitalize;
}
.email-box input {  
  font-size: 14px; 
}
.email-box button {
  font-size: 16px;
  font-weight: 600;
}
.tryIt-now h3 {
  font-size: 34px;
}
.cpr-section {
  font-size: 12px;
}
.paid-label { padding: 5px 8px; font-size: 13px; font-weight: 500; line-height: 1; }

/*.inner-bnr { height: 210px; }*/
.inner-page-heading h2 { font-size: 26px; }
.vdb-content h1 {
    font-size: 30px;
}
.vdb-heading {
    font-size: 28px;
}


/* .search-container .item{
  width: 298px
} */

}

@media(max-width: 991px) {
  .btm-shadow::after { bottom:-19px; height:20px; background-size: 100% 20px; }  
  .banner-shadow::after { height:20px; background-size: 100% 20px; }
  .copyright::after { top:-20px; height: 20px; background-size: 100% 20px; }
  #header {padding: 4px 0 0 0; }
  .banner-logo {width: 175px; }
  .banner-logo1 {
    width: 175px;
  }
  .banner-content { top:0;
     padding-bottom: 60px;
     /* padding-bottom: 0; */
     }
  .page-carousel h2 {font-size: 18px; }
  .tryIt-now h3 { font-size: 30px; }
  .copyright { padding:0 0 15px 0; }
  .tryIt-now p { font-size: 13px; }
  .page-carousel .item h4 {font-size: 13px; padding: 10px 15px; }  
  .page-carousel .owl-carousel .owl-nav button {opacity: 1; visibility: visible; }
  .cr-links a::before {height: 12px; font-size: 11px; }
  .cr-links a {padding: 0 7px; margin: 0 3px; }
  .cpr-section {font-size: 11px; }

  .user-profile-view { margin-right:0;/* margin-right: 20px; */}
  .mobile-nav-toggle { top:20px; }

  /*.inner-bnr { height: 180px; }*/
  .inner-page-heading h2 { font-size: 24px; }
  .inner-page-heading p { font-size: 13px; width: 80%; }
  /* .search-container .item{
    width: 298px
  } */
  .vdb-more-list li { width: 50%; }
  .vdb-heading { font-size: 24px; }
  .sub-item:hover .hide-item {
    display: none;  
  }
  .sub-item:hover { 
    position: static;
    /* -webkit-transform: -webkit-transform: scale(1.05, 1.05); */
  -ms-transform: scale(1, 1);
  -o-transform: scale(1, 1);
  transform: scale(1, 1);;
     /* -moz-transform: -webkit-transform: scale(1, 1); */
     -ms-transform: scale(1, 1);
     -o-transform: scale(1, 1);
     transform: scale(1, 1);;
      /* -ms-transform: -webkit-transform: scale(1, 1); */
      -ms-transform: scale(1, 1);
      -o-transform: scale(1, 1);
      transform: scale(1, 1);;
       /* -o-transform: -webkit-transform: scale(1, 1); */
       -ms-transform: scale(1, 1);
       -o-transform: scale(1, 1);
       transform: scale(1, 1);;
          /* transform: -webkit-transform: scale(1, 1); */
          -ms-transform: scale(1, 1);
          -o-transform: scale(1, 1);
          transform: scale(1, 1);
  }
  .video-des h1, .video-des h2, .video-des h3, .video-des h4, .video-des h5, .video-des h6{
    font-size: 12px;
    }
    
    .video-des h1::after, .video-des h2::after, .video-des h3::after, .video-des h4::after, .video-des h5::after, .video-des h6::after{
      display: none;
    }
    #header .navbar { padding-left: 15px; }
    .nav-menu > ul > li { margin:0; }
    .navbar-collapse {
      position: absolute;
      top: 100%;
      width: 100%;
      background: rgb(0, 0, 0);
      left: 0px;
      padding: 10px;
    }

    /* .poster {
      height: 50vh;
    } */
}

@media (min-width: 768px) and (max-width: 991px) {
  .search-container .item .sub-item .movie-poster img {
    /* height: 175px; */
    height:115px;
}
  .container-fluid.page-carousel.btm-shadow{
    overflow: hidden;
    }

    .sub-item .movie-poster img {
      height: 115px;
    }

   .movie-poster img {
      height: 115px;
    }

/* .sub-item:hover {
    height: 180px;
    bottom: -118px;
} */
.paid-label {
    padding: 5px 8px;
    font-size: 10px;
}
.page-carousel .item .sub-item h4 {
    font-size: 11px;
    padding: 8px 0;
    text-align: center;
}
  .play-icon {
      cursor: pointer;
      width: 15px!important;
      height: 15px;
  }
  .video-display{
  margin-bottom:0px;
  }
/* .sub-item:hover .hide-item {
    padding: 0px 5px;
} */
  .play {
      font-size: 9px;
  }
  .page-carousel .item .sub-item .svg-inline--fa.fa-w-14 {
      width: 0.475em;
  }
  .video-title {
      font-size: 9px;
     margin-bottom: 0;
     margin-top: -4px;
  }
.video-des {
    font-size: 9px;
    margin-bottom: 5px;
}
  .owl-carousel .owl-item .sub-item:hover img {
      height: 68%;
      object-fit: cover;
  }
  .video-con{
        margin-top: -5px;
  }
.video-duration {
    margin-right: 10px;
    font-size: 9px;
}
  .subscribers{
    font-size:9px;
  }
  .video-time{
        margin-top: -6px;
  }
  .red-btn, .dark-btn {    
    padding: 6px 18px;
  }
  .inner-pt {
    padding-top: 68px;
  }
  .sub-item { display: none;}
  /* .react-multi-carousel-list ul li { width: 220px !important; } */

 
}

@media(max-width: 767px) {
  
  #hero h1 {
  font-size: 20px;
  line-height: 1;
}
#hero .vai-text {
  font-size: 19px;
}

.inner-back-link{
  display: block;
  position: absolute;
    left: 12px;
    top: 75px;
    font-size: 12px;
    background: #555;
    border-radius: 5px;
    padding: 5px 10px;
    z-index: 9;
}

.sb-pt h2{
  padding-top: 40px;
}

.poster{
  background-size: auto 65%;
}

.banner-content { 
  padding-bottom: 70px;
}

  .blue-big-btn {
    font-size: 16px;
    padding: 1% 5%;
    font-weight: 600;
  }
.arrows div img {
  width: 16px;
}
.arrows div { margin-top: -18px; }
/* .poster { padding:135px 0 46px 0; } */
.banner-logo { width: 157px; }
.banner-logo1 { width: 122px; }


.btm-shadow::after {
  bottom: -14px;
  height: 15px;
  background-size: 100% 15px;
}

.container-fluid.page-carousel.btm-shadow{
  overflow: hidden;
  }

 .banner-shadow::after { height:15px; background-size: 100% 15px; }
  .copyright::after { top:-15px; height: 15px; background-size: 100% 15px; }
  .cpr-section {justify-content: center; align-items: center; flex-direction: column; }
  .cr-links { margin-bottom: 10px; }
  .tryIt-now h3 {font-size: 24px; }

.container-fluid { padding-left:12px; padding-right:15px; }
.page-carousel .owl-carousel .owl-nav { display: none; }
.page-carousel h2 { font-size: 16px; font-weight: 600; }
.email-box input { height: 40px; }

#header {padding: 4px 0 0 0; } 
#header .logo img {height: 25px; } 
.get-started-btn {margin: 0 10px 0 0; padding: 4px 15px; font-size: 13px; } 
.red-btn, .dark-btn { padding: 4px 15px; font-size: 13px; } 
.mobile-nav-toggle {top: 15px; font-size:22px; }


/* .sub-item:hover {
    height: 172px;
    bottom: -110px;
} */

.paid-label { font-size: 11px; }
.page-carousel .item .sub-item h4 {
  font-size: 10px;
  padding: 3px 0;
  text-align: center;
}
.play-icon {
  cursor: pointer;
  width: 15px!important;
  height: 15px;
}
.video-display{
margin-bottom:0px;
}
/* .sub-item:hover .hide-item {
 padding: 0px 3px;
} */
.play {
  font-size: 9px;
}
.page-carousel .item .sub-item .svg-inline--fa.fa-w-14 {
  width: 0.475em;
}
.video-title {
  font-size: 9px;
 margin-bottom: 0;
 margin-top: -4px;
}
.video-des {
    font-size: 8px;
    margin-bottom: 0px;
}
/* .owl-carousel .owl-item .sub-item:hover img {
  height: 68%;
  object-fit: cover;
} */
.video-con{
    margin-top: -5px;
}
.video-duration {
    margin-right: 10px;
    font-size: 9px;
    /* line-height: 1.5; */
}
.subscribers{
font-size:9px;
}
.video-time{
    margin-top: -2px;
}

  .user-profile-view { margin-right: 0; /*margin-right: 36px;*/ }
  .mobile-nav-toggle { top:18px; }
.add-new-profile, .back-link { position: static; }
.tc-heading { flex-direction: column; }
.inner-pt {
    padding-top: 60px;
}
.vdb-heading {
    font-size: 22px;
}
.search-sec { width: 175px; }

.rv-sec img { height: auto; }

.pt-page-error {
    padding-top: 30vh;
}

/* .react-multi-carousel-list ul li{
  width: 190px !important;
} */
.profile-sec .dropdown-toggle { margin-right: 10px; }
.sub-item:hover {
    position: absolute;
    transform: scale(1);
    top: 0;
}
.react-multi-carousel-list{
  overflow: hidden;
}
.video-header .MuiToolbar-regular {
 min-height: 38px;
 padding-top: 5px;
 padding-bottom: 5px
}
.video-header .MuiSvgIcon-root {
  width: 20px;
}

.video-header .MuiButtonBase-root{
   padding: 5px;
}
/* .poster {
  height: 40vh;
} */

}

@media(max-width: 600px) {
  #hero h2 { display: none; }
  .sub-item .movie-poster img {
    height: 120px;

  }
  .search-container .item .sub-item .movie-poster img {
    height:135px;
  }

  /* .poster {
    background-size: auto 70%;
  } */
  .movie-poster img {
    height: 120px;

  }
  .sub-item:hover {
    /* height: 154px;
    bottom: -80px; */
    /* top: 0px; */
  }
  .ac-setting h2 { font-size: 16px;  }
  .ac-setting p { font-size: 13px; }

  .profile-sec.dropdown .dropdown-menu { min-width: 260px; }
  .profile-sec.dropdown .profile-notification .pro-body li { width: 100%; }
  .profile-sec.dropdown .profile-notification .pro-body { padding:10px 0; }

  .vdb-more-list li {width: 100%; }
  .vdb-content h1 {font-size: 24px; }
  .video-detail-banner { min-height: inherit; }

  .vh-section {
    position: relative;
    align-items: flex-start;
  }

  .vh-poster img {
    object-position: top;
    max-height: inherit;
    width: 100px;
    height: 100px
  }

  .vh-left{
    align-items: flex-start;
  }

  .vh-right {
    margin-right: 0;
    position: absolute;
    right: 15px;
    top: 0px;
  }

  .vh-right button svg{
    width:20px;
    height: 20px
  }

  .vh-poster {
    margin-right: 15px;
  }

  .vh-content h5 {
    font-size: 16px;
    margin-bottom: 5px;
  }

  .wl-empty p{
    font-size: 13px;
  }

  /* .search-container .item .sub-item .movie-poster img {
    height: 165px;
  }   */

  .vdb-btns a {
    margin-right: 0;
    margin-bottom: 15px;
  }

  .vdb-btns { 
      display: inherit;
      /* justify-content: flex-start; */
      /* align-items: center; */
      flex-direction: column;
  }

  .vdb-btns .red-btn, .dark-btn {
    padding: 6px 15px;
    font-size: 14px;
  }
}

@media(max-width: 400px) {
  /* .poster { padding:188px 0 46px 0; } */
  /* .sub-item .movie-poster img{
    height: 90px;
  }

  .movie-poster img{
    height: 90px;
  } */

  .see-more-text{
    font-size: 12px;
  }

  .page-slider .carousel .slide img {
    height: 25vh !important;
  }

  /* .sub-item:hover {
      height: 134px;
      bottom: -60px;
  } */

  /* .owl-carousel .owl-item .sub-item:hover img {
    height: 53%;
    object-fit: cover;
  } */

  .page-carousel .item .sub-item h4 {
      font-size: 8px; 
  }
  .search-sec { width: 132px; margin: 0 9px; }
  /*.navbar { justify-content: flex-start; }*/
  .user-profile-view {
    margin-right: 0;
}
  .pb-3, .py-3 {
    padding-bottom: 0rem !important;
  }

  .search-container .item .sub-item .movie-poster img {
    height: 170px;
}

/* .react-multi-carousel-list ul li { width: 170px !important; } */
.search-container .item{
  margin-bottom: 55px;
}

.dropdown-item { white-space: normal;
}
.store-btns img {
  width: 115px;}
  .paypal-btns img {
    width: 150px;
  }
 
}
@media(max-width: 375px) {
  /* .sub-item:hover {
      height: 168px;
      bottom: -99px;
  } */
  /* .search-container .item .sub-item .movie-poster img {
    height: 170px;
  } */

  
}

@media(max-width: 360px) {
  /* .sub-item:hover {
      height: 134px;
      bottom: -60px;
  } */
}

@media (min-width: 576px){
  .col-sm-4 {
    flex: 0 0 33.333333%;
    max-width: 24.333333%;
}
}
